import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@sterling-react/grid';
import Button from '@mui/material/Button';
import { LocalMessage } from '@sterling-react/i18n';

import { AppContext } from './index';
import { conf } from '../../constant';

const Footer = () => {
  const { store } = useContext(AppContext);
  const { isLoading = false } = store;
  const location = useLocation();
  const { pathname = '/' } = location;

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={6}>
        {isLoading ? null : (
          <Button
            sx={{ textTransform: 'none' }}
            variant="contained"
            id="footer_redirect_button"
            color="inherit"
            href={conf.SR_URL}
          >
            <LocalMessage text="BACK_TO_SMARTRECRUITERS" />
          </Button>
        )}
        <span style={{ display: 'none' }} data-testid="footer_pathname">
          {pathname}
        </span>
      </Grid>
    </Grid>
  );
};

export default Footer;
