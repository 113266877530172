import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Pending from '../pending';
import Failed from '../failed';
import Loading from '../loading';

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/pending" element={<Pending />} />
      <Route path="/failed" element={<Failed />} />
      <Route path="/loading" element={<Loading />} />
      <Route path="/" element={<Loading />} />
    </Routes>
  );
};

export default CustomRoutes;
