import React, { useEffect, useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Grid from '@sterling-react/grid';
import queryString from 'query-string';
import get from 'lodash/get';

import { AppContext } from './index';
import { fetchCandidateInteractive, setOriginLocation } from './actions';

import SterlingLogo from '../images/sterlingLogo';

const Header = () => {
  const { store, dispatch } = useContext(AppContext);
  const {
    companyId: stCompanyId = '',
    sdUserId: stUserId = 0,
    redirRoute = '',
    originLocation = {},
  } = store;
  const location = useLocation();
  const { search = '', hash = '' } = location;
  const qs = queryString.parse(search);
  const qsHash = queryString.parse('?' + hash.substr(1));
  let {
    companyId: qsCompanyId = '',
    userId: qsUserId = 0,
    access_token: qsAccessToken,
    custId: qsCustId = 0,
  } = qs;

  if (!qsCompanyId) {
    qsCompanyId = get(qsHash, 'companyId') || '';
  }
  if (!qsUserId) {
    qsUserId = get(qsHash, 'userId') || '';
  }
  if (!qsAccessToken) {
    qsAccessToken = get(qsHash, 'access_token') || '';
  }
  if (!qsCustId) {
    qsCustId = get(qsHash, 'custId') || '';
  }

  useEffect(async () => {
    setOriginLocation(dispatch, {
      originLocation: location,
    });

    if (qsCompanyId && qsUserId && qsAccessToken && qsCustId) {
      await fetchCandidateInteractive(dispatch, {
        companyId: qsCompanyId,
        sdUserId: qsUserId,
        accessToken: qsAccessToken,
        custId: qsCustId,
      });
    }
  }, []);

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={6}>
        <div style={{ lineHeight: '2em' }}>
          <br />
        </div>
        <SterlingLogo />
      </Grid>
      {!redirRoute ? (
        <span style={{ display: 'none' }}>...</span>
      ) : (
        <Navigate to={redirRoute} />
      )}
      <code style={{ display: 'none' }} data-testid="header_user_id">
        {stUserId}
      </code>
      <code style={{ display: 'none' }} data-testid="header_company_id">
        {stCompanyId}
      </code>
      <code style={{ display: 'none' }} data-testid="header_origin_location">
        {JSON.stringify(originLocation, null, 2)}
      </code>
    </Grid>
  );
};

export default Header;
