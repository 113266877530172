import React from 'react';
import Grid from '@sterling-react/grid';
import Typography from '@sterling-react/typography';
import CircularProgress from '@sterling-react/circular-progress';
import { LocalMessage } from '@sterling-react/i18n';

const Loading = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      data-testid="container-loading"
    >
      <Grid item xs={6}>
        <div style={{ textAlign: 'center', margin: '2em auto' }}>
          <CircularProgress color="primary" size={96} />
        </div>
        <Typography align="center" component="h2" variant="headline" paragraph>
          <LocalMessage text="LOADING" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Loading;
