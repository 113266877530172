import React from 'react';
import Grid from '@sterling-react/grid';
import versionJson from '../../version.json';

const Version = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      data-testid="container-version"
    >
      <Grid item xs={6}>
        <pre style={{ fontSize: '0.8em' }}>
          <code>{JSON.stringify(versionJson, null, 2)}</code>
        </pre>
      </Grid>
    </Grid>
  );
};

export default Version;
