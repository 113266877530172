import React from 'react';
import Grid from '@sterling-react/grid';
import Typography from '@sterling-react/typography';
import { LocalMessage } from '@sterling-react/i18n';

import { $css } from '../../constant';

const Pending = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      data-testid="container-pending"
    >
      <Grid item xs={6}>
        <div style={$css.resultTypography}></div>
        <div style={$css.resultTypography}>
          <Typography align="left" component="h2" variant="headline" paragraph>
            <LocalMessage text="THANK_YOU" />
          </Typography>
        </div>
        <div style={$css.resultTypography}>
          <Typography align="left" component="h2" variant="headline" paragraph>
            <LocalMessage text="INTEGRATION_PENDING_1" />
          </Typography>
        </div>
        <div style={$css.resultTypography}>
          <Typography align="left" component="h2" variant="headline" paragraph>
            <LocalMessage text="INTEGRATION_PENDING_2" />
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Pending;
