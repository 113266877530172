export const conf = {
  HOSTNAME: process.env.REACT_APP_HOSTNAME,
  API_URL: process.env.REACT_APP_API_URL,
  SR_URL: process.env.REACT_APP_SR_URL,
  RES_STATUS: {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    PRECONDITION_FAILED: 412,
    INTERNAL_SERVER_ERROR: 500,
  },
};

export const dictionary = () => ({
  en: {
    translation: {
      LOADING: 'Loading ...',
      THANK_YOU: 'Thank you for your request.',
      BACK_TO_SMARTRECRUITERS: 'Back to SmartRecruiters',
      INTEGRATION_FAILED_1:
        'Sorry, your integration with Sterling could not be activated at this time. Please try again.',
      INTEGRATION_FAILED_2:
        'If the issue continues, please contact your Sterling Client Success Representative for assistance. Thank you.',
      INTEGRATION_PENDING_1:
        'Our team has received your request to integrate your Sterling account with SmartRecruiters. The integration will be activated within 24 hours. Once completed, you will see the Sterling integration as "Active" in the "Apps & Integrations" settings in the SmartRecruiters platform.',
      INTEGRATION_PENDING_2:
        'If you have additional questions, please reach out to your Sterling client success representative.',
    },
  },
  es: {
    translation: {
      THANK_YOU: 'Gracias!',
    },
  },
});

export const server = {
  FULL_PATH: {
    CANDIDATE_INTERACTIVE:
      conf.API_URL +
      '/screenings/onboarding/candidate-interactive/SmartRecruiters/Default',
  },
};

export const $css = {
  resultTypography: {
    marginBottom: '3em',
  },
};
