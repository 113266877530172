import set from 'lodash/set';
import axios from 'axios';
import queryString from 'query-string';

import { server } from '../../constant';

const postfixRequest = '_REQUEST';
const postfixSuccess = '_SUCCESS';
const postfixFailure = '_FAILURE';

const _types = {};

export const createCandidateInteractiveActions = (actionName) => {
  const actionRequest = `${actionName}${postfixRequest}`;
  const actionSuccess = `${actionName}${postfixSuccess}`;
  const actionFailure = `${actionName}${postfixFailure}`;

  set(_types, actionRequest, actionRequest);
  set(_types, actionSuccess, actionSuccess);
  set(_types, actionFailure, actionFailure);

  return async (dispatch, data) => {
    const errors = [];
    const {
      companyId = '',
      sdUserId = 0,
      accessToken = '',
      custId = '',
    } = data;

    !companyId && errors.push('Empty parameter: companyId');
    !sdUserId && errors.push('Empty parameter: sdUserId');
    !accessToken && errors.push('Empty parameter: accessToken');
    !custId && errors.push('Empty parameter: custId');

    if (errors.length > 0) {
      dispatch({ type: actionFailure, payload: { errors } });
    } else {
      dispatch({
        type: actionRequest,
        payload: { companyId, sdUserId, accessToken, custId },
      });
      try {
        const apiQs = {
          companyId,
          ScreeningDirectUserId: sdUserId,
          accessToken,
          custId,
        };
        const res = await axios.post(
          server.FULL_PATH.CANDIDATE_INTERACTIVE,
          queryString.stringify(apiQs),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );
        const { status = 0 } = res;
        switch (true) {
          case status >= 200 && status < 400:
            dispatch({
              type: actionSuccess,
              payload: res.data,
            });
            break;
          default:
            dispatch({
              type: actionFailure,
              payload: res.data,
              errors: [{ message: 'Unexpected HTTP response code', status }],
            });
            break;
        }
      } catch (ex) {
        dispatch({ type: actionFailure, payload: { errors: [ex] } });
      }
    }
  };
};

export const fetchCandidateInteractive = createCandidateInteractiveActions(
  'FETCH_CANDIDATE_INTERACTIVE',
);

const setOriginLocationEventType = 'SET_ORIGINLOCATION';
set(_types, setOriginLocationEventType, setOriginLocationEventType);

export const setOriginLocation = (dispatch, data) => {
  dispatch({ type: setOriginLocationEventType, payload: data });
};

export const types = _types;
