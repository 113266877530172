import React, { useReducer } from 'react';
import { Sterling2019Theme } from '@sterling-react/theme-provider';
import ThemeProvider from '@sterling-react/theme-provider';
import { LanguageProvider } from '@sterling-react/i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { FormWorkflowProvider } from '@sterling-react/form';

import { dictionary } from '../../constant';
import { initialStore, reducer } from './reducer';
import CustomRoutes from './routes';
import Header from './header';
import Footer from './footer';
import Version from '../version';

export const AppContext = React.createContext();

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialStore);
  return (
    <ThemeProvider theme={Sterling2019Theme}>
      <LanguageProvider messages={dictionary()}>
        <AppContext.Provider value={{ store, dispatch }}>
          {/* <FormWorkflowProvider> */}
          <Router>
            <Routes>
              <Route path="/version" element={<Version />} />
              <Route
                path="/*"
                element={[
                  <Header key="header" />,
                  <CustomRoutes key="customRoutes" />,
                  <Footer key="footer" />,
                ]}
              />
            </Routes>
          </Router>
        </AppContext.Provider>
        {/* </FormWorkflowProvider> */}
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
