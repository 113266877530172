import { types } from './actions';

export const initialStore = {
  errors: null,
  code: '',
  message: '',
  sdUserId: null,
  companyId: null,
  accessToken: null,
  custId: null,
  isLoading: true,
  redirRoute: '',
  originLocation: null,
};

export const reducer = (store = initialStore, action) => {
  const { payload = null, type = '' } = action;
  switch (type) {
    case types.FETCH_CANDIDATE_INTERACTIVE_REQUEST:
      return {
        ...store,
        ...payload,
        isLoading: true,
        redirRoute: '',
      };
    case types.FETCH_CANDIDATE_INTERACTIVE_SUCCESS:
      return {
        ...store,
        ...payload,
        isLoading: false,
        redirRoute: 'pending',
      };
    case types.FETCH_CANDIDATE_INTERACTIVE_FAILURE:
      return {
        ...store,
        errors: payload.errors,
        isLoading: false,
        redirRoute: 'failed',
      };
    case types.SET_ORIGINLOCATION:
      return !store.originLocation
        ? {
            ...store,
            ...payload,
          }
        : store;
    default:
      return store;
  }
};
