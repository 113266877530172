import React from 'react';

const SterlingLogo = () => {
  return (
    <img
      alt="Sterling"
      style={{ height: '100px' }}
      src="https://cdn.backgroundcheck.com/logos/Sterling.svg"
    />
  );
};

export default SterlingLogo;
